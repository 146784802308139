import { Pagination } from "@nextui-org/react";
import { Clinic } from "../../../models/Clinic";
import { ClinicCard } from "../../Molecules/ClinicCard";
import { useNavigate } from "react-router-dom";

interface ClinicsListProps {
  clinics: Clinic[];
  totalClinics: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  examId: string;
}

export const ClinicsList: React.FC<ClinicsListProps> = ({
  clinics,
  totalClinics,
  page,
  pageSize,
  onPageChange,
  examId,
}) => {
  const navigate = useNavigate();

  const handleClinicClick = (clinic: Clinic) => {
    navigate(`/clinics/${clinic.id}/booking?exam=${examId}`);
  };

  const totalPages = Math.ceil(totalClinics / pageSize);

  return (
    <div className="flex max-lg:flex-col flex-wrap lg:max-w-5xl gap-2 items-center justify-center pb-20">
      {clinics.map((clinic) => (
        <ClinicCard
          key={clinic.id}
          clinic={clinic}
          onCardClick={() => handleClinicClick(clinic)}
        />
      ))}

      <Pagination
        total={totalPages}
        initialPage={1}
        page={page}
        onChange={onPageChange}
      />
    </div>
  );
};
