import * as S from "./styles";
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import { Breadcrumbs, Button, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { ProviderInformation } from "../../models/ProviderInformation";
import { useProvidersQuery } from "../../hooks/queries/useProviderQuery";
import { EditProviderLogic } from "../../components/EditProviderLogic";
import { AddNewProviderLogic } from "../../components/AddNewProviderLogic";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { useHealthInsurancesQuery } from "../../hooks/queries/useHealthInsurancesQuery";

export const AdminProviders: React.FC = () => {
  const providersQuery = useProvidersQuery();
  const { data: healthInsurances } = useHealthInsurancesQuery();
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderInformation | null>(null);

  const columns: GridColDef[] = [
    {
      field: "disabled",
      headerName: "Ativo",
      width: 20,
      renderCell: ({ value }) => (
        <div className="m-auto">
          {value ? (
            <Cancel style={{ color: "#c83333" }} />
          ) : (
            <CheckCircle style={{ color: "#004600" }} />
          )}
        </div>
      ),
    },
    {
      field: "clinic",
      headerName: "Clínica",
      flex: 1,
      valueGetter: ({ row }) =>
        (row as ProviderInformation).relatedClinic?.name || "",
    },
    { field: "name", headerName: "Nome", flex: 1 },
    {
      field: "specialties",
      headerName: "Especializações",
      flex: 1,
      valueGetter: (params) => {
        return (params.row.specialties || []).join(", ");
      },
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "crmCode", headerName: "CRM", flex: 1 },
    {
      field: "acceptedHealthInsurances",
      headerName: "Convênios",
      flex: 1,
      valueGetter: (params) => {
        return (params.row.acceptedHealthInsurances || []).join(", ");
      },
    },
    {
      field: "acceptedPaymentMethods",
      headerName: "Métodos de Pagamento",
      flex: 1,
      valueGetter: (params) => {
        return (params.row.acceptedPaymentMethods || []).join(", ");
      },
    },
    {
      field: "action",
      headerName: "Ações",
      flex: 1,
      sortable: false,
      renderCell: ({ id }) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              providersQuery.data &&
                setSelectedProvider(
                  providersQuery.data.find((clinic) => clinic.id === id) || null
                );
            }}
          >
            Editar
          </Button>
        );
      },
    },
  ];

  async function onProviderAdded(providerId: string) {
    const newProvidersQuery = await providersQuery.refetch();
    const addedProvider = newProvidersQuery.data?.find(
      (provider) => provider.id === providerId
    );
    setSelectedProvider(addedProvider || null);
  }

  return (
    <S.Container>
      <div className="d-flex justify-content-between">
        <Breadcrumbs sx={{ p: 1, borderRadius: 2 }} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/admin">
            Inicio
          </Link>
          <Typography color="text.primary">Profissionais</Typography>
        </Breadcrumbs>

        <AddNewProviderLogic onProviderAdded={onProviderAdded} />
      </div>
      <EditProviderLogic
        selectedProvider={selectedProvider}
        healthInsurances={healthInsurances?.map((hi) => hi.id) || []}
        onClose={() => setSelectedProvider(null)}
      />
      <DataGrid
        rows={providersQuery.data || []}
        columns={columns}
        loading={providersQuery.isFetching}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        sx={{
          background: "#fff9f9",
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        pageSizeOptions={[5, 10]}
        isRowSelectable={() => false}
      />
    </S.Container>
  );
};
