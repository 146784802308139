import { useQuery } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
  where,
  limit,
  orderBy,
  getCountFromServer,
  startAfter,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { Clinic } from "../../models/Clinic";
import { PaymentMethod } from "../../models/PaymentMethod";
import { parseCurrency } from "../../utils/currencyUtils";

interface UseClinicQueryParams {
  stateId?: string;
  cityId?: string;
  examId?: string;
  filterText?: string;
  paymentMethods?: PaymentMethod[];
  selectedHealthInsurance?: string;
  priceRange?: [number, number];
  page: number;
  pageSize: number;
}

const fetchClinics = async (): Promise<Clinic[]> => {
  const clinicQuery = query(collection(fireStoreDb, "clinics"));

  const querySnapshot = await getDocs(clinicQuery);

  const clinics = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {
        timezone: "America/Sao_Paulo",
      };
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
        timezone: "America/Sao_Paulo",
      } as Clinic;
    })
  );

  return clinics;
};

export const useClinicsQuery = () => {
  return useQuery<Clinic[], Error>({
    queryKey: ["clinics", "list"],
    queryFn: () => fetchClinics(),
  });
};

export const useSearchClinicsQuery = ({
  stateId,
  cityId,
  examId,
  filterText,
  paymentMethods,
  selectedHealthInsurance,
  priceRange,
  page,
  pageSize,
}: UseClinicQueryParams) => {
  return useQuery<{ clinics: Clinic[]; totalCount: number }, Error>({
    queryKey: [
      "clinics",
      {
        stateId,
        cityId,
        examId,
        filterText,
        paymentMethods,
        selectedHealthInsurance,
        priceRange,
        page,
        pageSize,
      },
    ],
    queryFn: async () => {
      let q = query(collection(fireStoreDb, "clinics"), orderBy("name"));

      if (stateId) {
        q = query(q, where("state.id", "==", stateId));
      }
      if (cityId) {
        q = query(q, where("city.id", "==", cityId));
      }

      if (paymentMethods && paymentMethods.length > 0) {
        q = query(
          q,
          where("acceptedPaymentMethods", "array-contains-any", paymentMethods)
        );
      }

      // Get total count
      const totalCountSnapshot = await getCountFromServer(q);
      const totalCount = totalCountSnapshot.data().count;

      // Apply pagination
      const startAtDoc = await getStartAtDoc(q, page, pageSize);
      q = query(q, startAfter(startAtDoc), limit(pageSize));

      const querySnapshot = await getDocs(q);

      const clinics = querySnapshot.docs
        .map((doc) => {
          const clinicData = doc.data() as Clinic;

          if (!clinicData.schedules || !clinicData.schedules.exams) {
            return null;
          }

          if (examId && clinicData.schedules?.exams?.exams) {
            const hasExam = Object.values(
              clinicData.schedules.exams.exams
            ).some((exam) => {
              const hasMatchingExam =
                exam.name?.toLowerCase() === examId.toLowerCase();
              const hasMatchingInsurance =
                !selectedHealthInsurance ||
                exam.acceptedHealthInsurances?.includes(
                  selectedHealthInsurance
                );
              const price = parseCurrency(exam.price as string);
              const isInPriceRange =
                !priceRange ||
                (price >= priceRange[0] && price <= priceRange[1]);
              return hasMatchingExam && hasMatchingInsurance && isInPriceRange;
            });
            if (!hasExam) {
              return null;
            }
          }

          if (filterText && filterText.trim() !== "") {
            const lowercaseFilter = filterText.toLowerCase();
            if (!clinicData.name?.toLowerCase().includes(lowercaseFilter)) {
              return null;
            }
          }

          // Only return clinics with all required fields
          if (!clinicData.name) {
            return null;
          }

          return {
            ...clinicData,
            id: doc.id,
            timezone: "America/Sao_Paulo",
          } as Clinic;
        })
        .filter((clinic): clinic is Clinic => clinic !== null);

      return { clinics, totalCount };
    },
    enabled: !!(stateId && cityId),
  });
};

// Helper function to get the document to start after for pagination
const getStartAtDoc = async (q: any, page: number, pageSize: number) => {
  if (page === 1) return null;
  const startAtSnapshot = await getDocs(query(q, limit((page - 1) * pageSize)));
  return startAtSnapshot.docs[startAtSnapshot.docs.length - 1];
};
