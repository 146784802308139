import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@nextui-org/react";
import { Dayjs } from "dayjs";
import { useAuth } from "../../../contexts/auth.context";
import { useProviderDetailsQuery } from "../../../hooks/queries/useProviderDetailsQuery";
import { useScheduleAppointment } from "../../../hooks/queries/useScheduleAppointment";
import { calculateAvailableTimesPerDay } from "../../FormModalLogic/utils";
import { DateUtils } from "../../../utils/dateUtils";
import { BookingSkeleton } from "../../Organisms/BookingSkeleton";
import { BookingError } from "../../Organisms/BookingError";
import { AppointmentScheduler } from "../../Organisms/AppointmentScheduler";
import { ProviderReviews } from "../../Organisms/ProviderReviews";
import { Schedule } from "../../../models/Schedule";
import { appointmentObjectives } from "../../../utils/appointmentObjectives";
import { BookingTemplate } from "../../Templates/BookingTemplate";
import { BookingHeader } from "../../Organisms/BookingHeader";
import { BookingForm } from "../../Organisms/BookingForm";
import { ModalitySelector } from "../../Molecules/ModalitySelector";
import { AppointmentStatus } from "../../../models/Appointment";
import { useAppointmentsQuery } from "../../../hooks/queries/useAppointmentsQuery";
import {
  PAYMENT_METHOD_LABELS,
  PaymentMethod,
} from "../../../models/PaymentMethod";
import { ObjectiveSelector } from "../../Organisms/ObjectiveSelector";
import { ServiceSelection } from "../../Atoms/ServiceSelection";

export const ProviderBooking = () => {
  const { providerId } = useParams();
  const { currentUser } = useAuth();
  const [reason, setReason] = useState<string | null>(null);
  const [modality, setModality] = useState<string>("private");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedHealthInsurance, setSelectedHealthInsurance] = useState<
    string | null
  >(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);

  const { data: providerDetails, isLoading: isLoadingProvider } =
    useProviderDetailsQuery(providerId);
  const { data: appointments, isLoading: isLoadingAppointments } =
    useAppointmentsQuery({ providerId });

  const scheduleAppointment = useScheduleAppointment();

  const conflictingAppointmentsSet = useMemo(() => {
    const set = new Set<string>();
    appointments?.forEach((appointment) => {
      if (appointment.status === AppointmentStatus.CONFIRMED) {
        // For confirmed appointments, only disable the selectedOption
        if (appointment.selectedOption) {
          set.add(appointment.selectedOption);
        }
      } else if (appointment.status === AppointmentStatus.WAITING_PROVIDER) {
        // For waiting provider status, disable all options
        [appointment.option1, appointment.option2, appointment.option3]
          .filter(Boolean)
          .forEach((option) => set.add(option as string));
      }
    });
    return set;
  }, [appointments]);

  const availableTimes = useMemo(() => {
    if (!providerDetails) return;

    return calculateAvailableTimesPerDay(
      providerDetails.timezone,
      providerDetails.schedules.appointments as Schedule,
      false,
      undefined,
      selectedService as string,
      conflictingAppointmentsSet
    );
  }, [providerDetails]);

  const handleTimeSelection = (day: Dayjs, time: string) => {
    const selectedTime = DateUtils.toDBFormat(
      day
        .hour(parseInt(time.split(":")[0]))
        .minute(parseInt(time.split(":")[1])),
      providerDetails?.timezone
    );

    if (selectedTime) {
      setSelectedOptions((prev) => {
        if (prev.includes(selectedTime)) {
          return prev.filter((t) => t !== selectedTime);
        }
        if (prev.length >= 3) return prev;
        return [...prev, selectedTime];
      });
    }
  };

  const isDayDisabled = (day: Dayjs) => {
    const dayOfWeek = day.day();
    const scheduleToUse = providerDetails?.schedules.appointments;

    return !!scheduleToUse?.disabledDays?.includes(dayOfWeek);
  };

  const isTimeDisabled = (day: Dayjs, time: string) => {
    if (!providerDetails) return true;

    const providerTime = day
      .tz(providerDetails.timezone)
      .hour(parseInt(time.split(":")[0]))
      .minute(parseInt(time.split(":")[1]));

    // Check if the time is in the past
    if (providerTime.isBefore(DateUtils.now(providerDetails.timezone))) {
      return true;
    }

    // Check if the time is disabled in the provider's schedule
    const dayOfWeek = providerTime.day();
    const scheduleToUse = providerDetails.schedules.appointments;
    if (scheduleToUse?.disabledTimes?.[dayOfWeek]?.includes(time)) {
      return true;
    }

    // Convert to UTC and check against the conflicting appointments set
    const utcTimeString = DateUtils.toDBFormat(
      providerTime,
      providerDetails.timezone
    );
    return utcTimeString ? conflictingAppointmentsSet.has(utcTimeString) : true;
  };

  const acceptedHealthInsurances = useMemo(() => {
    if (!selectedService || !providerDetails) return [];

    const serviceData =
      providerDetails.schedules.appointments?.services?.[selectedService] ||
      providerDetails.schedules.exams?.exams?.[selectedService];

    if (serviceData?.acceptsHealthInsurances) {
      return (
        serviceData.acceptedHealthInsurances ||
        providerDetails.acceptedHealthInsurances ||
        []
      );
    }

    return [];
  }, [selectedService, providerDetails]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    scheduleAppointment.mutate({
      provider: providerDetails,
      reason: reason as string,
      serviceId: selectedService as string,
      selectedOptions,
      paymentMethod: selectedPaymentMethod
        ? PAYMENT_METHOD_LABELS[selectedPaymentMethod]
        : undefined,
      healthInsurance: selectedHealthInsurance,
    });
  };

  if (isLoadingProvider || isLoadingAppointments) return <BookingSkeleton />;
  if (!providerDetails) return <BookingError />;

  return (
    <BookingTemplate
      title="Agendar Consulta"
      header={
        <BookingHeader
          provider={providerDetails}
          acceptedPaymentMethods={providerDetails.acceptedPaymentMethods}
          contactInfo={{
            phoneNumber: providerDetails.relatedClinic?.phoneNumber as string,
            name: providerDetails.relatedClinic?.name as string,
            address: providerDetails.relatedClinic?.address as string,
            timezone: providerDetails.timezone,
          }}
          userTimezone={currentUser.timezone}
        />
      }
    >
      <BookingForm onSubmit={handleSubmit}>
        <ServiceSelection
          selectedService={selectedService}
          services={providerDetails.schedules.appointments?.services}
          onServiceChange={setSelectedService}
        />

        <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
          <div className="w-full bg-default-100 rounded-medium p-2">
            <h2 className="text-default-700 font-semibold text-sm">
              Modalidade
            </h2>
          </div>

          <ModalitySelector
            modality={modality}
            setModality={setModality}
            healthInsurances={acceptedHealthInsurances}
            paymentMethods={
              providerDetails.acceptedPaymentMethods as PaymentMethod[]
            }
            setSelectedHealthInsurance={setSelectedHealthInsurance}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            selectedMedicalService={selectedService}
            schedules={providerDetails.schedules}
          />
        </div>

        {providerDetails.schedules.appointments?.services?.[
          selectedService as string
        ]?.acceptsObjectiveSelection && (
          <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
            <div className="w-full bg-default-100 rounded-medium p-2">
              <h2 className="text-default-700 font-semibold text-sm">
                Objetivo do Agendamento
              </h2>
            </div>
            <ObjectiveSelector
              objectives={appointmentObjectives}
              onObjectiveSelect={setReason}
            />
          </div>
        )}
        <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
          <AppointmentScheduler
            availableTimes={availableTimes as string[]}
            onTimeSelection={handleTimeSelection}
            selectedOptions={selectedOptions}
            isDayDisabled={isDayDisabled}
            isTimeDisabled={isTimeDisabled}
            providerTimezone={providerDetails.timezone}
            userTimezone={currentUser.timezone}
            medicalServiceDuration={
              providerDetails.schedules.appointments?.services?.[
                selectedService as string
              ]?.duration as number
            }
            isExam={false}
          />

          <Button
            color="primary"
            type="submit"
            size="lg"
            radius="md"
            isLoading={scheduleAppointment.isPending}
            isDisabled={
              !selectedService ||
              selectedOptions.length === 0 ||
              (modality === "healthInsurance" && !selectedHealthInsurance) ||
              (modality === "private" && !selectedPaymentMethod)
            }
          >
            Enviar sugestões
          </Button>
        </div>
      </BookingForm>

      <ProviderReviews provider={providerDetails} />
    </BookingTemplate>
  );
};
