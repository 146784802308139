// import { useDisclosure } from "@nextui-org/react";
// import { useAuth } from "../../../contexts/auth.context";
import { Pagination } from "@nextui-org/react";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { ProviderCard } from "../../Molecules/ProviderCard";
// import { PhoneVerificationModal } from "../PhoneVerificationModal";
import { useNavigate } from "react-router-dom";

interface ProvidersListProps {
  providers: ProviderInformation[];
  totalProviders: number;
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
}

export const ProvidersList: React.FC<ProvidersListProps> = ({
  providers,
  totalProviders,
  page,
  pageSize,
  onPageChange,
}) => {
  // const { currentUser } = useAuth();
  const navigate = useNavigate();
  // const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleProviderClick = (provider: ProviderInformation) => {
    // if (currentUser.phoneConfirmed === false) {
    // onOpen();
    // } else {
    navigate(`/providers/${provider.id}/booking`);
    // }
  };

  const totalPages = Math.ceil(totalProviders / pageSize);

  return (
    <div className="flex max-lg:flex-col flex-wrap lg:max-w-5xl gap-2 items-center justify-center pb-20">
      {providers.map((provider) => (
        <ProviderCard
          key={provider.id}
          provider={provider}
          onCardClick={() => handleProviderClick(provider)}
        />
      ))}

      <Pagination
        total={totalPages}
        initialPage={1}
        page={page}
        onChange={onPageChange}
      />

      {/* {currentUser.phoneConfirmed === false && (
        <PhoneVerificationModal isOpen={isOpen} onOpenChange={onOpenChange} />
        )} */}
    </div>
  );
};
