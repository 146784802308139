import React, { useState, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Autocomplete, AutocompleteItem, Button } from "@nextui-org/react";
import { useAuth } from "../../../contexts/auth.context";
import { useClinicDetailsQuery } from "../../../hooks/queries/useClinicDetailsQuery";
import { useProvidersQuery } from "../../../hooks/queries/useProviderQuery";
import { useScheduleAppointment } from "../../../hooks/queries/useScheduleAppointment";
import { calculateAvailableTimesPerDay } from "../../FormModalLogic/utils";
import { DateUtils } from "../../../utils/dateUtils";
import { BookingSkeleton } from "../../Organisms/BookingSkeleton";
import { BookingError } from "../../Organisms/BookingError";
import { AppointmentScheduler } from "../../Organisms/AppointmentScheduler";
import { BookingTemplate } from "../../Templates/BookingTemplate";
import { BookingHeader } from "../../Organisms/BookingHeader";
import { BookingForm } from "../../Organisms/BookingForm";
import { ModalitySelector } from "../../Molecules/ModalitySelector";
import { ProviderInformation } from "../../../models/ProviderInformation";
import { Dayjs } from "dayjs";
import { Clinic } from "../../../models/Clinic";
import { ExamSelection } from "../../Atoms/ExamSelection";
import { AppointmentStatus } from "../../../models/Appointment";
import { useAppointmentsQuery } from "../../../hooks/queries/useAppointmentsQuery";
import {
  PAYMENT_METHOD_LABELS,
  PaymentMethod,
} from "../../../models/PaymentMethod";

export const ClinicBooking = () => {
  const { clinicId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const examId = searchParams.get("exam");

  const { currentUser } = useAuth();
  const [modality, setModality] = useState("private");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedHealthInsurance, setSelectedHealthInsurance] = useState<
    string | null
  >(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null);
  const [selectedExam, setSelectedExam] = useState<string>(examId as string);
  const [selectedProvider, setSelectedProvider] =
    useState<ProviderInformation | null>(null);

  const { data: clinicDetails, isLoading: isLoadingClinic } =
    useClinicDetailsQuery(clinicId);
  const { data: providersList, isLoading: isLoadingProviders } =
    useProvidersQuery(clinicId, selectedExam as string);
  const { data: clinicAppointments, isLoading: isLoadingClinicAppointments } =
    useAppointmentsQuery({ clinicId });
  const {
    data: providerAppointments,
    isLoading: isLoadingProviderAppointments,
  } = useAppointmentsQuery({
    providerId: selectedProvider?.id,
  });

  const scheduleAppointment = useScheduleAppointment();

  const isProviderExam = providersList && providersList.length > 0;

  const conflictingAppointmentsSet = useMemo(() => {
    const set = new Set<string>();
    const appointments =
      isProviderExam && selectedProvider
        ? providerAppointments
        : clinicAppointments;

    appointments?.forEach((appointment) => {
      if (appointment.status === AppointmentStatus.CONFIRMED) {
        if (appointment.selectedOption) {
          set.add(appointment.selectedOption);
        }
      } else if (appointment.status === AppointmentStatus.WAITING_PROVIDER) {
        [appointment.option1, appointment.option2, appointment.option3]
          .filter(Boolean)
          .forEach((option) => set.add(option as string));
      }
    });
    return set;
  }, [
    isProviderExam,
    selectedProvider,
    clinicAppointments,
    providerAppointments,
  ]);

  const availableTimes = useMemo(() => {
    if (!clinicDetails || (isProviderExam && !selectedProvider)) return;

    const scheduleToUse = isProviderExam
      ? selectedProvider?.schedules.exams
      : clinicDetails?.schedules?.exams;

    if (!scheduleToUse) return;

    return calculateAvailableTimesPerDay(
      isProviderExam
        ? (selectedProvider?.timezone as string)
        : (clinicDetails.timezone as string),
      scheduleToUse,
      true,
      selectedExam,
      undefined,
      conflictingAppointmentsSet
    );
  }, [
    clinicDetails,
    isProviderExam,
    selectedProvider,
    selectedExam,
    conflictingAppointmentsSet,
  ]);

  const handleTimeSelection = (day: Dayjs, time: string) => {
    const selectedTime = DateUtils.toDBFormat(
      day
        .hour(parseInt(time.split(":")[0]))
        .minute(parseInt(time.split(":")[1])),
      clinicDetails?.timezone
    );

    if (selectedTime) {
      setSelectedOptions((prev) => {
        if (prev.includes(selectedTime)) {
          return prev.filter((t) => t !== selectedTime);
        }
        if (prev.length >= 3) return prev;
        return [...prev, selectedTime];
      });
    }
  };

  const isDayDisabled = (day: Dayjs) => {
    const dayOfWeek = day.day();

    const scheduleToUse = isProviderExam
      ? selectedProvider?.schedules.exams
      : clinicDetails?.schedules?.exams;

    return !!scheduleToUse?.disabledDays?.includes(dayOfWeek);
  };

  const isTimeDisabled = (day: Dayjs, time: string) => {
    if (!clinicDetails) return true;

    const timeDate = day
      .tz(clinicDetails.timezone)
      .hour(parseInt(time.split(":")[0]))
      .minute(parseInt(time.split(":")[1]));

    // Check if the time is in the past
    if (timeDate.isBefore(DateUtils.now(clinicDetails.timezone))) {
      return true;
    }

    // Check if the time is disabled in the schedule
    const dayOfWeek = timeDate.day();
    const scheduleToUse = isProviderExam
      ? selectedProvider?.schedules.exams
      : clinicDetails?.schedules?.exams;

    if (scheduleToUse?.disabledTimes?.[dayOfWeek]?.includes(time)) {
      return true;
    }

    // Convert to UTC and check against the conflicting appointments set
    const utcTimeString = DateUtils.toDBFormat(
      timeDate,
      clinicDetails.timezone
    );
    return utcTimeString ? conflictingAppointmentsSet.has(utcTimeString) : true;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    scheduleAppointment.mutate({
      clinic: clinicDetails as Clinic,
      provider: isProviderExam
        ? (selectedProvider as ProviderInformation)
        : undefined,
      selectedOptions,
      paymentMethod: selectedPaymentMethod
        ? PAYMENT_METHOD_LABELS[selectedPaymentMethod]
        : undefined,
      healthInsurance: selectedHealthInsurance,
      examId: selectedExam,
    });
  };

  const handleExamChange = (examId: React.Key | null) => {
    if (typeof examId === "string") {
      setSelectedExam(examId);
      setSearchParams({ exam: examId });
    }
    setSelectedProvider(null);
  };

  const acceptedHealthInsurances = useMemo(() => {
    if (!selectedExam || (!clinicDetails && !selectedProvider)) return [];

    const examData =
      isProviderExam && selectedProvider
        ? selectedProvider.schedules.exams?.exams?.[selectedExam]
        : clinicDetails?.schedules?.exams?.exams?.[selectedExam];

    if (examData?.acceptsHealthInsurances) {
      return (
        examData.acceptedHealthInsurances ||
        (isProviderExam
          ? selectedProvider?.acceptedHealthInsurances
          : clinicDetails?.acceptedHealthInsurances) ||
        []
      );
    }

    return [];
  }, [selectedExam, clinicDetails, selectedProvider, isProviderExam]);

  if (
    isLoadingClinic ||
    isLoadingProviders ||
    isLoadingClinicAppointments ||
    (isProviderExam && isLoadingProviderAppointments)
  ) {
    return <BookingSkeleton />;
  }
  if (!clinicDetails) return <BookingError />;

  return (
    <BookingTemplate
      title="Agendar Exame"
      header={
        <BookingHeader
          clinic={clinicDetails}
          acceptedPaymentMethods={clinicDetails.acceptedPaymentMethods}
          contactInfo={{
            phoneNumber: clinicDetails.phoneNumber as string,
            name: clinicDetails.name as string,
            address: clinicDetails.address as string,
            timezone: clinicDetails.timezone,
          }}
          userTimezone={currentUser.timezone}
        />
      }
    >
      <BookingForm onSubmit={handleSubmit}>
        <ExamSelection
          selectedExam={selectedExam}
          offeredExams={clinicDetails.schedules?.exams?.exams}
          onExamChange={handleExamChange}
        />

        {isProviderExam && (
          <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
            <div className="w-full bg-default-100 rounded-medium p-2">
              <h2 className="text-default-700 font-semibold text-sm">
                Profissional Responsável
              </h2>
            </div>
            <Autocomplete
              size="lg"
              radius="md"
              variant="bordered"
              label="Selecione o profissional"
              selectedKey={selectedProvider?.id}
              onSelectionChange={(value) => {
                const provider = providersList.find(
                  (provider) => provider.id === value
                );
                setSelectedProvider(provider || null);
              }}
              classNames={{
                clearButton: "w-12",
              }}
            >
              {providersList.map((provider) => (
                <AutocompleteItem
                  key={provider.id as string}
                  value={provider.id}
                  color="secondary"
                  variant="flat"
                >
                  {provider.name}
                </AutocompleteItem>
              ))}
            </Autocomplete>
          </div>
        )}

        {(!isProviderExam || (isProviderExam && selectedProvider)) && (
          <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
            <div className="w-full bg-default-100 rounded-medium p-2">
              <h2 className="text-default-700 font-semibold text-sm">
                Modalidade
              </h2>
            </div>
            {clinicDetails.acceptedHealthInsurances && (
              <ModalitySelector
                modality={modality}
                setModality={setModality}
                healthInsurances={acceptedHealthInsurances}
                setSelectedHealthInsurance={setSelectedHealthInsurance}
                paymentMethods={
                  isProviderExam && selectedProvider
                    ? (selectedProvider.acceptedPaymentMethods as PaymentMethod[])
                    : (clinicDetails.acceptedPaymentMethods as PaymentMethod[])
                }
                selectedPaymentMethod={selectedPaymentMethod}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                selectedMedicalService={selectedExam}
                schedules={
                  isProviderExam && selectedProvider
                    ? selectedProvider.schedules
                    : clinicDetails.schedules
                }
              />
            )}
          </div>
        )}

        <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
          {(!isProviderExam || (isProviderExam && selectedProvider)) && (
            <AppointmentScheduler
              availableTimes={availableTimes as string[]}
              onTimeSelection={handleTimeSelection}
              selectedOptions={selectedOptions}
              isDayDisabled={isDayDisabled}
              isTimeDisabled={isTimeDisabled}
              providerTimezone={
                isProviderExam
                  ? (selectedProvider?.timezone as string)
                  : clinicDetails.timezone
              }
              userTimezone={currentUser.timezone}
              isExam={true}
              medicalServiceDuration={
                isProviderExam
                  ? (selectedProvider?.schedules.exams?.exams?.[selectedExam]
                      ?.duration as number)
                  : (clinicDetails?.schedules?.exams?.exams?.[selectedExam]
                      ?.duration as number)
              }
            />
          )}
          <Button
            color="primary"
            type="submit"
            size="lg"
            isLoading={scheduleAppointment.isPending}
            isDisabled={
              selectedOptions.length === 0 ||
              (modality === "healthInsurance" && !selectedHealthInsurance) ||
              (modality === "private" && !selectedPaymentMethod) ||
              !selectedExam ||
              ((isProviderExam && !selectedProvider) as boolean)
            }
          >
            Agendar
          </Button>
        </div>
      </BookingForm>
    </BookingTemplate>
  );
};
