export const GradientLogo = ({ className = "" }) => (
  <svg
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1107.12 225"
    className={className}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="0"
        y1="112.5"
        x2="225"
        y2="112.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#2C92E5" />
        <stop offset="1" stopColor="#882BE6" />
      </linearGradient>
    </defs>
    <g id="Layer_1-2" data-name="Layer_1">
      <g>
        <path
          fill="url(#linear-gradient)"
          d="M225,65.9C225,29.5,195.5,0,159.1,0h0c-36.19,0-65.56,29.18-65.88,65.3h67.69c7.78,0,14.09,6.31,14.09,14.09,0,3.43-1.23,6.57-3.26,9.01-.4.54-.83,1.06-1.32,1.55l-57.17,57.06h47.66c7.78,0,14.09,6.31,14.09,14.09s-6.31,14.09-14.09,14.09h-81.81c-7.78,0-14.09-6.31-14.09-14.09,0-4.12,1.78-7.82,4.6-10.39l57.34-57.23h-48.96c-.19,0-.38-.02-.56-.03h-11.64C29.45,93.46,0,122.91,0,159.23h0c0,36.32,29.45,65.77,65.77,65.77h93.46c36.32,0,65.77-29.45,65.77-65.77h0c0-.1,0-.19,0-.29,0-.1,0-.19,0-.29v-92.76Z"
        />
        <g>
          <g>
            <path
              fill="#882BE6"
              d="M285.31,131.51l55.31-38.38h-55.31v-23.6h95.84v23.6l-55.67,38.38h55.67v23.6h-95.84v-23.6Z"
            />
            <path fill="#882BE6" d="M394.04,69.53h28.01v85.59h-28.01v-85.59Z" />
            <path
              fill="#882BE6"
              d="M438.38,69.53h66.99c21.34,0,34.33,11.92,34.33,32.19s-12.99,32.19-34.33,32.19h-38.98v21.22h-28.01v-85.59ZM498.7,110.41c7.99,0,11.8-1.67,11.8-8.7s-3.81-8.58-11.8-8.58h-32.3v17.29h32.3Z"
            />
          </g>
          <path
            fill="#882BE6"
            d="M553.27,126.86h29.09c0,6.2,6.44,10.37,25.03,10.37s22.53-2.62,22.53-7.15c0-5.24-3.22-6.32-23.96-7.63l-4.17-.24c-32.78-1.91-47.21-10.01-47.21-28.37s18.72-26.34,48.16-26.34c33.62,0,51.62,10.25,52.69,29.21h-29.09c0-6.56-7.75-8.94-23.84-8.94-15.14,0-18.84,2.38-18.84,6.08,0,4.41,3.22,6.08,22.41,7.39l4.89.36c29.21,2.27,48.04,5.36,48.04,27.42s-18.83,28.49-50.42,28.49c-34.45,0-54.24-8.23-55.31-30.64Z"
          />
          <path
            fill="#882BE6"
            d="M705.02,69.53h35.76l43.75,85.59h-29.92l-7.51-15.85h-48.52l-7.63,15.85h-29.8l43.87-85.59ZM737.45,119.12l-14.66-30.64-14.66,30.64h29.32Z"
          />
          <path
            fill="#882BE6"
            d="M785.73,113.28v-43.75h28.01v42.79c0,10.73,4.77,21.58,24.08,21.58s23.96-10.97,23.96-21.58v-42.79h28.13v43.75c0,28.73-15.5,44.22-52.09,44.22s-52.09-15.5-52.09-44.22Z"
          />
          <path
            fill="#882BE6"
            d="M902.68,69.53h61.15c24.8,0,42.79,17.88,42.79,42.68s-18,42.91-42.79,42.91h-61.15v-85.59ZM952.51,131.51c16.93,0,25.15-6.44,25.15-19.31s-8.22-19.07-25.15-19.07h-21.81v38.38h21.81Z"
          />
          <path
            fill="#882BE6"
            d="M1019.62,69.53h87.5v21.1h-59.48v12.04h57.1v19.19h-57.1v12.16h59.48v21.1h-87.5v-85.59Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
