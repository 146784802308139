import React from "react";
import {
  TextField,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";
import { formatCurrency } from "../../utils/currencyUtils";
import { HealthInsuranceAutoComplete } from "../HealthInsuranceAutoComplete";

interface ExamDetailsInputProps {
  details: ProviderInformation | Clinic;
  setDetails: React.Dispatch<
    React.SetStateAction<ProviderInformation | Clinic>
  >;
}

export const ExamDetailsInput: React.FC<ExamDetailsInputProps> = ({
  details,
  setDetails,
}) => {
  const handleExamChange = (
    examId: string,
    field: string,
    value: string | number | boolean | string[]
  ) => {
    setDetails((prevDetails) => {
      const updatedExams = {
        ...prevDetails.schedules?.exams?.exams,
        [examId]: {
          ...prevDetails.schedules?.exams?.exams?.[examId],
          [field]: value,
        },
      };

      if ("schedules" in prevDetails) {
        // ProviderInformation case
        return {
          ...prevDetails,
          schedules: {
            ...prevDetails.schedules,
            exams: {
              ...prevDetails.schedules?.exams,
              exams: updatedExams,
            },
          },
        };
      } else {
        // Clinic case
        return {
          ...prevDetails,
          schedules: {
            ...prevDetails.schedules,
            exams: {
              ...prevDetails.schedules?.exams,
              exams: updatedExams,
            },
          },
        };
      }
    });
  };

  const getExamDetails = (examId: string) => {
    if ("schedules" in details) {
      // ProviderInformation case
      return details.schedules?.exams?.exams?.[examId] || {};
    } else {
      // Clinic case
      return details.schedules?.exams?.exams?.[examId] || {};
    }
  };

  const exams =
    "schedules" in details
      ? Object.keys(details.schedules?.exams?.exams || {})
      : Object.keys(details.schedules?.exams?.exams || {});

  return (
    <div>
      <Typography variant="h6">Detalhes dos Exames</Typography>
      <Grid container spacing={2}>
        {exams.map((examId: string) => {
          const examDetails = getExamDetails(examId);
          return (
            <React.Fragment key={examId}>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Nome do Exame`}
                  variant="outlined"
                  value={examDetails.name || ""}
                  onChange={(e) =>
                    handleExamChange(examId, "name", e.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Duração do Exame (minutos)`}
                  type="number"
                  variant="outlined"
                  value={examDetails.duration || ""}
                  onChange={(e) =>
                    handleExamChange(examId, "duration", Number(e.target.value))
                  }
                  fullWidth
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={`Valor do Exame`}
                  variant="outlined"
                  value={examDetails.price || ""}
                  onChange={(e) => {
                    const formattedValue = formatCurrency(e.target.value);
                    handleExamChange(examId, "price", formattedValue);
                  }}
                  fullWidth
                  InputProps={{
                    inputProps: { inputMode: "numeric" },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={examDetails.acceptsHealthInsurances || false}
                      onChange={(e) =>
                        handleExamChange(
                          examId,
                          "acceptsHealthInsurances",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Aceita Plano de Saúde"
                />
              </Grid>
              {examDetails.acceptsHealthInsurances && (
                <Grid item xs={12}>
                  <HealthInsuranceAutoComplete
                    placeholder="Planos de Saúde Aceitos Pelo Exame"
                    value={examDetails.acceptedHealthInsurances || []}
                    setValue={(value) =>
                      handleExamChange(
                        examId,
                        "acceptedHealthInsurances",
                        value
                      )
                    }
                  />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
};
