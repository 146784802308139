import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  auth,
  fireStoreDb,
  googleProvider,
  messaging,
} from "../services/firebase/firebase";
import {
  ApplicationVerifier,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  linkWithCredential,
  PhoneAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getToken } from "firebase/messaging";
import { useRecaptcha } from "./recaptcha.context";
import dayjs from "dayjs";
import { UserInformation } from "../models/UserInformation";
import { DateUtils } from "../utils/dateUtils";

export interface SignUpCredentials {
  email: string;
  password: string;
  role: string;
}

export interface UserCredentials {
  email: string;
  password: string;
}

export type AuthContextUserProps = {
  uid: string;
  email: string | null;
  phoneNumber?: string | null;
  name?: string | null;
  photoURL?: string | null;
  emailVerified: boolean;
  phoneConfirmed?: boolean;
  role: any;
  timezone: string;
  googleAccessToken?: string;
  googleAccessTokenExpiration?: string | null;

  location?: UserInformation["location"];
};

export type AuthContextProps = {
  signed: boolean;
  loading: boolean;
  updateUserLoading: boolean;
  currentUser: AuthContextUserProps;
  googleAccessToken: string | null;
  googleAccessTokenExpiration: string | null;

  updateUser: (updatedData: Partial<AuthContextUserProps>) => void;
  handleSignUp: (credentials: SignUpCredentials) => void;
  handleSignIn: (credentials: UserCredentials) => void;
  handleGoogleSignIn: () => Promise<string | undefined>;
  handleResetPassword: (email: string) => void;
  handleSignOut: () => void;
  handleSendPhoneVerificationCode: (phoneNumber: string) => Promise<string>;
  handleVerifyPhoneCode: (
    phoneNumber: string,
    verificationId: string,
    verificationCode: string
  ) => Promise<void>;
  checkAuthStatus: () => boolean;
};

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
);

function isKeyOfAuthContextUserProps(
  key: string
): key is keyof AuthContextUserProps {
  return key in ({} as AuthContextUserProps);
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const { executeRecaptcha, recaptchaVerifier } = useRecaptcha();

  const [currentUser, setCurrentUser] = useState<AuthContextUserProps>(
    {} as AuthContextUserProps
  );
  const [loading, setLoading] = useState(true);
  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState<string | null>(
    null
  );
  const [googleAccessTokenExpiration, setGoogleAccessTokenExpiration] =
    useState<string | null>(null);

  const getDataStored = useCallback(() => {
    const storedUser = localStorage.getItem("@ZIP:user");

    if (storedUser) {
      setCurrentUser(JSON.parse(storedUser));
    }
  }, []);

  const saveFCMToken = useCallback(async (uid: string) => {
    try {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BF24nzWTu3GM0WbFPXJAwtx85P4kXImzpPRuQLabJuFj8whv75VbXejjVTtbEpAWGFg4k_3pU3kZEqUJEpazsVY",
      });

      if (currentToken) {
        const userRef = doc(fireStoreDb, "users", uid);
        await updateDoc(userRef, { fcmToken: currentToken });
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    } catch (err) {
      console.error("An error occurred while saving the FCM token: ", err);
    }
  }, []);

  useEffect(() => {
    if (!auth) return;

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        setCurrentUser({} as AuthContextUserProps);
        localStorage.removeItem("@ZIP:user");
      } else {
        const userDoc = await getDoc(doc(fireStoreDb, "users", user.uid));

        if (userDoc.exists()) {
          const userData = userDoc.data();

          const updatedUser: AuthContextUserProps = {
            uid: user.uid,
            email: user.email,
            phoneNumber: userData.phoneNumber,
            phoneConfirmed: userData.phoneConfirmed,
            emailVerified: user.emailVerified,
            role: userData?.role,
            name: user.displayName,
            photoURL: user.photoURL,
            timezone: userData.timezone,
            location: userData?.location,
            googleAccessToken: userData.googleAccessToken,
          };

          await saveFCMToken(user.uid);
          setCurrentUser(updatedUser);

          localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
        } else {
          console.error("User document not found");
          setCurrentUser({} as AuthContextUserProps);
          localStorage.removeItem("@ZIP:user");
        }
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [saveFCMToken]);

  async function updateUser(updatedData: Partial<AuthContextUserProps>) {
    try {
      setUpdateUserLoading(true);
      const user = auth.currentUser;

      if (!user) {
        throw new Error("No authenticated user found");
      }

      const userRef = doc(fireStoreDb, "users", user.uid);

      // Filter out undefined values and ensure type safety
      const filteredData = Object.entries(updatedData).reduce(
        (acc, [key, value]) => {
          if (value !== undefined && isKeyOfAuthContextUserProps(key)) {
            acc[key] = value;
          }
          return acc;
        },
        {} as Partial<AuthContextUserProps>
      );

      await updateDoc(userRef, filteredData);

      // Update local user state
      setCurrentUser((prevUser) => ({
        ...prevUser,
        ...filteredData,
      }));

      // Update local storage
      const updatedUser = {
        ...currentUser,
        ...filteredData,
      };
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
    } catch (error) {
      console.error("Error updating user:", error);
      throw error;
    } finally {
      setUpdateUserLoading(false);
    }
  }

  async function handleSignUp(credentials: SignUpCredentials) {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );

      const newUser = userCredential.user;

      await setDoc(doc(fireStoreDb, "users", newUser.uid), {
        email: newUser.email,
        role: credentials.role,
        phoneConfirmed: false,
        timezone: dayjs.tz.guess(),
      });

      const updatedUser = {
        uid: newUser.uid,
        email: newUser.email,
        emailVerified: newUser.emailVerified,
        role: credentials.role,
        phoneConfirmed: false,
        timezone: dayjs.tz.guess(),
      };

      setCurrentUser(updatedUser);
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

      if (credentials.role === "customer") {
        navigate("/");
      } else {
        navigate("/provider");
      }
    } catch (error: any) {
      console.error("Error registering user:", error);
      throw new Error(error);
    }
  }

  async function handleSignIn(credentials: UserCredentials) {
    try {
      await executeRecaptcha();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      );
      const user = userCredential.user;

      const userDoc = await getDoc(doc(fireStoreDb, "users", user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        let updatedUser: AuthContextUserProps = {
          uid: user.uid,
          email: user.email,
          phoneNumber: userData.phoneNumber,
          phoneConfirmed: userData.phoneConfirmed,
          emailVerified: user.emailVerified,
          role: userData?.role,
          name: user.displayName,
          photoURL: user.photoURL,
          timezone: userData.timezone,
          location: userData?.location,
        };

        setCurrentUser(updatedUser);
        localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

        switch (userData.role) {
          case "admin":
            navigate("/admin/clinics");
            break;
          case "provider":
            navigate("/appointments");
            break;
          default:
            navigate("/");
            break;
        }
      } else {
        console.error("User document not found");
      }
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  async function handleGoogleSignIn(): Promise<string | undefined> {
    try {
      googleProvider.setCustomParameters({ prompt: "select_account" });
      googleProvider.addScope("https://www.googleapis.com/auth/calendar");

      const result = await signInWithPopup(auth, googleProvider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;

      if (!token) {
        throw new Error("Failed to obtain Google access token");
      }

      const user = result.user;
      const userRef = doc(fireStoreDb, "users", user.uid);
      const userDoc = await getDoc(userRef);

      const now = DateUtils.now();
      const tokenExpiration = DateUtils.toDBFormat(now.add(1, "hour"));

      let updatedUser: AuthContextUserProps;

      setGoogleAccessToken(token);
      setGoogleAccessTokenExpiration(tokenExpiration);

      if (!userDoc.exists()) {
        // New user, create a new document
        updatedUser = {
          uid: user.uid,
          email: user.email,
          phoneNumber: user.phoneNumber,
          name: user.displayName,
          photoURL: user.photoURL,
          emailVerified: user.emailVerified,
          role: "customer",
          phoneConfirmed: false,
          timezone: dayjs.tz.guess(),
          googleAccessToken: token,
          googleAccessTokenExpiration: tokenExpiration,
        };

        await setDoc(userRef, { ...updatedUser, createdAt: new Date() });
      } else {
        // Existing user, update the document
        const userData = userDoc.data();
        updatedUser = {
          uid: user.uid,
          email: user.email,
          phoneNumber: userData?.phoneNumber,
          phoneConfirmed: userData?.phoneConfirmed,
          emailVerified: user.emailVerified,
          name: user.displayName,
          photoURL: user.photoURL,
          role: userData?.role,
          location: userData?.location,
          timezone: userData?.timezone,
          googleAccessToken: token,
          googleAccessTokenExpiration: tokenExpiration,
        };

        // Filter out undefined values and ensure type safety
        const filteredData = Object.entries(updatedUser).reduce(
          (acc, [key, value]) => {
            if (value !== undefined && isKeyOfAuthContextUserProps(key)) {
              acc[key] = value;
            }
            return acc;
          },
          {} as Partial<AuthContextUserProps>
        );

        await updateDoc(userRef, filteredData);
      }

      setCurrentUser(updatedUser);
      localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));

      return token;
    } catch (error) {
      console.error("Error signing in with Google:", error);
      throw error;
    }
  }

  async function handleResetPassword(email: string) {
    try {
      await sendPasswordResetEmail(auth, email);

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error: any) {
      console.log(error);
      throw new Error(error);
    }
  }

  async function handleSignOut() {
    try {
      setCurrentUser({} as AuthContextUserProps);

      await signOut(auth);

      localStorage.removeItem("@ZIP:user");

      navigate("/");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  }

  const handleSendPhoneVerificationCode = async (
    phoneNumber: string
  ): Promise<string> => {
    try {
      if (!recaptchaVerifier) {
        await executeRecaptcha();
      }

      const phoneProvider = new PhoneAuthProvider(auth);

      const verificationId = await phoneProvider.verifyPhoneNumber(
        `+55 ${phoneNumber}`,
        recaptchaVerifier as ApplicationVerifier
      );

      return verificationId;
    } catch (error: any) {
      console.error(
        "Detailed error in sending phone verification code:",
        error
      );
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      throw error;
    }
  };

  const handleVerifyPhoneCode = async (
    phoneNumber: string,
    verificationId: string,
    verificationCode: string
  ) => {
    try {
      const credential = PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const user = auth.currentUser;

      if (user) {
        await linkWithCredential(user, credential);
        await updateDoc(doc(fireStoreDb, "users", user.uid), {
          phoneNumber: `+55${phoneNumber}`,
          phoneConfirmed: true,
        });

        // Update local user state
        setCurrentUser((prevUser) => ({
          ...prevUser,
          phoneNumber: `+55${phoneNumber}`,
          phoneConfirmed: true,
        }));

        // Update local storage
        const updatedUser = {
          ...currentUser,
          phoneNumber: `+55${phoneNumber}`,
          phoneConfirmed: true,
        };
        localStorage.setItem("@ZIP:user", JSON.stringify(updatedUser));
      } else {
        throw new Error("No authenticated user found");
      }
    } catch (error: any) {
      console.error("Error verifying phone code:", error);
      throw error;
    }
  };

  useEffect(() => {
    getDataStored();
  }, [getDataStored]);

  const checkAuthStatus = () => {
    return !loading && Boolean(currentUser.email);
  };

  return (
    <AuthContext.Provider
      value={{
        signed: checkAuthStatus(),
        loading,
        currentUser,
        googleAccessToken,
        googleAccessTokenExpiration,
        updateUser,
        updateUserLoading,
        handleSignUp,
        handleSignIn,
        handleGoogleSignIn,
        handleResetPassword,
        handleSignOut,
        checkAuthStatus,
        handleSendPhoneVerificationCode,
        handleVerifyPhoneCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};
