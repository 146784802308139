import * as S from "./styles";
import { DataGrid, GridColDef, ptBR } from "@mui/x-data-grid";
import { Breadcrumbs, Button, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import { AddNewClinicLogic } from "../../components/AddNewClinicLogic";
import { useState } from "react";
import { Clinic } from "../../models/Clinic";
import { EditClinicLogic } from "../../components/EditClinicLogic";
import { useClinicsQuery } from "../../hooks/queries/useClinicQuery";

export const AdminClinics: React.FC = () => {
  const clinicsList = useClinicsQuery();
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Nome", flex: 1 },
    { field: "cnpj", headerName: "CNPJ", flex: 1 },
    { field: "address", headerName: "Endereço", flex: 1 },
    { field: "phoneNumber", headerName: "Telefone", flex: 1 },
    {
      field: "acceptedHealthInsurances",
      headerName: "Convênios",
      flex: 1,
      valueGetter: (params) => {
        return (params.row.acceptedHealthInsurances || []).join(", ");
      },
    },
    {
      field: "acceptedPaymentMethods",
      headerName: "Métodos de Pagamento",
      flex: 1,
      valueGetter: (params) => {
        return (params.row.acceptedPaymentMethods || []).join(", ");
      },
    },
    { field: "openingHours", headerName: "Horário", flex: 1 },
    {
      field: "action",
      headerName: "Ação",
      flex: 1,
      sortable: false,
      renderCell: ({ id }) => {
        return (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => {
              clinicsList.data &&
                setSelectedClinic(
                  clinicsList.data.find((clinic) => clinic.id === id) || null
                );
            }}
          >
            Editar
          </Button>
        );
      },
    },
  ];

  return (
    <S.Container>
      <div className="d-flex justify-content-between">
        <Breadcrumbs sx={{ p: 1, borderRadius: 2 }} aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/admin">
            Inicio
          </Link>
          <Typography color="text.primary">Clínicas</Typography>
        </Breadcrumbs>
        <AddNewClinicLogic />
      </div>
      <EditClinicLogic
        selectedClinic={selectedClinic}
        onClose={() => setSelectedClinic(null)}
      />
      <DataGrid
        rows={clinicsList.data || []}
        columns={columns}
        loading={clinicsList.isFetching}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        sx={{
          background: "#fff9f9",
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        pageSizeOptions={[5, 10]}
        isRowSelectable={() => false}
      />
    </S.Container>
  );
};
