import { Button } from "@nextui-org/react";
import { ReactNode } from "react";
import { GradientLogo } from "../../Atoms/GradientLogo";

interface AuthFormProps {
  title: string;
  subtitle: string;
  children: ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  isFormValid: boolean;
  isLoading: boolean;
  submitText: string;
  links?: ReactNode;
}

export const AuthForm: React.FC<AuthFormProps> = ({
  title,
  subtitle,
  children,
  onSubmit,
  isFormValid,
  isLoading,
  submitText,
  links,
}) => (
  <main className="flex items-center justify-center min-h-svh flex-col gap-16 mx-6">
    <GradientLogo className="w-64" />
    <div className="flex flex-col gap-8 max-md:w-full">
      <div className="flex flex-col gap-1">
        <h2 className="font-bold text-3xl">{title}</h2>
        <p className="font-medium text-medium">{subtitle}</p>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col gap-4">
        {children}
        <div className="flex flex-col gap-2">
          <Button
            color="primary"
            className="font-medium w-full h-12"
            isDisabled={!isFormValid}
            isLoading={isLoading}
            size="lg"
            radius="lg"
            type="submit"
          >
            {submitText}
          </Button>
          {links}
        </div>
      </form>
    </div>
  </main>
);
