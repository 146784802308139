import { useQuery } from "@tanstack/react-query";
import {
  getDocs,
  query,
  collection,
  getDoc,
  doc as docRef,
  where,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { ProviderInformation } from "../../models/ProviderInformation";
import { Clinic } from "../../models/Clinic";
import { PaymentMethod } from "../../models/PaymentMethod";
import { parseCurrency } from "../../utils/currencyUtils";

interface UseProviderQueryParams {
  stateId?: string;
  cityId?: string;
  specialtyId?: string;
  serviceId?: string;
  filterText?: string;
  paymentMethods?: PaymentMethod[];
  selectedHealthInsurance?: string;
  priceRange?: [number, number];
  page: number;
  pageSize: number;
}

const fetchProviders = async (
  relatedClinicId?: string,
  examId?: string
): Promise<ProviderInformation[]> => {
  let providerQuery = query(collection(fireStoreDb, "providers"));

  if (relatedClinicId) {
    providerQuery = query(
      collection(fireStoreDb, "providers"),
      where("relatedClinicId", "==", relatedClinicId)
    );
  }

  if (examId) {
    providerQuery = query(
      providerQuery,
      where("exams", "array-contains", examId)
    );
  }

  const querySnapshot = await getDocs(providerQuery);

  const providers = await Promise.all(
    querySnapshot.docs.map(async (doc) => {
      let relatedClinic: Clinic = {} as Clinic;
      if (doc.data().relatedClinicId) {
        const clinicDoc = await getDoc(
          docRef(fireStoreDb, "clinics", doc.data().relatedClinicId as string)
        );
        relatedClinic = clinicDoc.data() as Clinic;
      }
      return {
        ...doc.data(),
        id: doc.id,
        relatedClinic,
      } as ProviderInformation;
    })
  );

  // Sort providers by `averageRating`, placing those without a rating at the bottom
  return providers.sort((a, b) => {
    const ratingA = a.averageRating ?? -1; // Place providers without a rating last
    const ratingB = b.averageRating ?? -1;
    return ratingB - ratingA; // Sort descending by rating
  });
};

export const useProvidersQuery = (
  relatedClinicId?: string,
  examId?: string
) => {
  return useQuery<ProviderInformation[], Error>({
    queryKey: ["providers", "list", relatedClinicId, examId],
    queryFn: () => fetchProviders(relatedClinicId, examId),
    enabled: !!relatedClinicId || !!examId || (!relatedClinicId && !examId), // Enable query when conditions are met
  });
};

export const useSearchProvidersQuery = ({
  stateId,
  cityId,
  specialtyId,
  serviceId,
  filterText,
  paymentMethods,
  selectedHealthInsurance,
  priceRange,
  page,
  pageSize,
}: UseProviderQueryParams) => {
  return useQuery<
    { providers: ProviderInformation[]; totalCount: number },
    Error
  >({
    queryKey: [
      "providers",
      {
        stateId,
        cityId,
        specialtyId,
        serviceId,
        filterText,
        paymentMethods,
        selectedHealthInsurance,
        priceRange,
        page,
        pageSize,
      },
    ],
    queryFn: async () => {
      let q = query(collection(fireStoreDb, "providers"), orderBy("name"));

      if (paymentMethods && paymentMethods.length > 0) {
        q = query(
          q,
          where("acceptedPaymentMethods", "array-contains-any", paymentMethods)
        );
      }

      // Apply pagination
      const startAtDoc = await getStartAtDoc(q, page, pageSize);
      q = query(q, startAfter(startAtDoc), limit(pageSize));

      const querySnapshot = await getDocs(q);

      const providers = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          let relatedClinic: Clinic | null = null;
          let providerData = doc.data() as ProviderInformation;

          if (providerData.disabled) {
            return null;
          }

          if (
            !providerData.schedules ||
            (!providerData.schedules.appointments &&
              !providerData.schedules.exams)
          ) {
            return null;
          }

          if (providerData.relatedClinicId) {
            const clinicDoc = await getDoc(
              docRef(fireStoreDb, "clinics", providerData.relatedClinicId)
            );
            relatedClinic = clinicDoc.data() as Clinic;

            if (
              (stateId && relatedClinic.state?.id !== stateId) ||
              (cityId && relatedClinic.city?.id !== cityId)
            ) {
              return null;
            }
          }

          if (
            filterText &&
            !providerData?.name
              ?.toLowerCase()
              .includes(filterText.toLowerCase())
          ) {
            return null;
          }

          // Check for specialty
          if (
            specialtyId &&
            (!providerData.specialties ||
              !providerData.specialties.includes(specialtyId))
          ) {
            return null;
          }

          if (serviceId && providerData.schedules?.appointments?.services) {
            const hasService = Object.values(
              providerData.schedules.appointments.services
            ).some((service) => {
              const hasMatchingService =
                service.name?.toLowerCase() === serviceId.toLowerCase();
              const hasMatchingInsurance =
                !selectedHealthInsurance ||
                service.acceptedHealthInsurances?.includes(
                  selectedHealthInsurance
                );
              const price = parseCurrency(service.price as string);
              const isInPriceRange =
                !priceRange ||
                (price >= priceRange[0] && price <= priceRange[1]);
              return (
                hasMatchingService && hasMatchingInsurance && isInPriceRange
              );
            });
            if (!hasService) {
              return null;
            }
          }

          return providerData;
        })
      );

      const filteredProviders = providers.filter(
        (provider): provider is ProviderInformation => provider !== null
      );

      return {
        providers: filteredProviders,
        totalCount: filteredProviders.length,
      };
    },
    enabled: !!(specialtyId || serviceId),
  });
};

// Helper function to get the document to start after for pagination
const getStartAtDoc = async (q: any, page: number, pageSize: number) => {
  if (page === 1) return null;
  const startAtSnapshot = await getDocs(query(q, limit((page - 1) * pageSize)));
  return startAtSnapshot.docs[startAtSnapshot.docs.length - 1];
};
