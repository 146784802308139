import React from "react";
import { Autocomplete, AutocompleteItem } from "@nextui-org/react";
import { Schedule } from "../../../models/Schedule";

interface ServiceSelectionProps {
  selectedService: string | null;
  services: Schedule["services"];
  onServiceChange: (serviceId: string | null) => void;
}

export const ServiceSelection: React.FC<ServiceSelectionProps> = ({
  selectedService,
  services,
  onServiceChange,
}) => (
  <div className="flex flex-col w-full gap-2 p-2 bg-white rounded-large">
    <div className="w-full bg-gray-100 rounded-medium p-2">
      <h2 className="text-gray-700 font-semibold text-sm">Serviço</h2>
    </div>
    {services && Object.keys(services).length > 0 ? (
      <Autocomplete
        size="lg"
        radius="md"
        variant="bordered"
        label="Selecione um serviço"
        selectedKey={selectedService}
        onSelectionChange={(value) => onServiceChange(value as string)}
        classNames={{
          clearButton: "w-12",
        }}
      >
        {Object.entries(services).map(([serviceId, serviceDetails]) => (
          <AutocompleteItem
            key={serviceId}
            color="secondary"
            variant="flat"
            textValue={serviceDetails.name || ""}
          >
            <div className="flex justify-between items-center">
              <span>{serviceDetails.name}</span>
              {serviceDetails.price && (
                <span className="text-success-600 font-semibold">
                  {serviceDetails.price}
                </span>
              )}
            </div>
          </AutocompleteItem>
        ))}
      </Autocomplete>
    ) : (
      <p className="text-sm text-danger-600">
        Nenhum serviço disponível no momento.
      </p>
    )}
  </div>
);
