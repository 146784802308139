import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireStoreDb, functions } from "../../services/firebase/firebase";
import { Appointment } from "../../models/Appointment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { httpsCallable } from "firebase/functions";
import { DateUtils } from "../../utils/dateUtils";

interface CancelAppointmentParams {
  id: string;
  role: "customer" | "provider";
}

const cancelAppointment = async ({ id, role }: CancelAppointmentParams) => {
  try {
    const appointmentDoc = await getDoc(doc(fireStoreDb, "appointments", id));
    const userId = appointmentDoc.data()?.userId;
    const providerId = appointmentDoc.data()?.providerId;
    const date = appointmentDoc.data()?.selectedOption;

    await setDoc(
      doc(fireStoreDb, "appointments", id),
      {
        status: "CANCELLED",
      } as Appointment,
      { merge: true }
    );

    if (userId) {
      const sendNotification = httpsCallable(
        functions,
        "sendAppointmentNotification"
      );

      const { formattedDate: displayDate, formattedTime: displayTime } =
        DateUtils.getFormattedDateAndTime(date);

      let bodyMessage;
      if (date) {
        bodyMessage = `Seu agendamento Zip Saúde no dia ${displayDate} às ${displayTime} foi cancelado. Toque para ver mais informações.`;
      } else if (role === "provider") {
        bodyMessage =
          "Um profissional cancelou o seu agendamento Zip Saúde pendente. Toque para ver mais informações";
      } else {
        bodyMessage =
          "Um paciente cancelou um agendamento Zip Saúde ainda pendente. Toque para ver mais informações";
      }

      try {
        await sendNotification({
          appointmentId: id,
          userId: role === "provider" ? userId : providerId,
          title: "Agendamento Cancelado",
          body: bodyMessage,
          URL: `/appointments/${id}`,
        });
        console.log("Cancellation notification sent successfully");
      } catch (error) {
        throw error;
      }
    }
  } catch (error) {
    throw error;
  }
};

export const useCancelAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: cancelAppointment,

    onMutate: async (newAppointment) => {
      await queryClient.cancelQueries({ queryKey: ["appointments"] });
      const previousAppointments = queryClient.getQueryData(["appointments"]);

      queryClient.setQueryData(["appointments"], (old: any) => {
        if (!old || !old.pages) {
          console.warn("Appointments data structure is not as expected");
          return old;
        }

        return {
          ...old,
          pages: old.pages.map((page: Appointment[]) =>
            Array.isArray(page)
              ? page.map((appointment) =>
                  appointment.id === newAppointment.id
                    ? { ...appointment, status: "CANCELLED" }
                    : appointment
                )
              : page
          ),
        };
      });

      return { previousAppointments };
    },

    onError: (error, _, context) => {
      console.error("Error in cancelAppointment mutation:", error);
      if (context?.previousAppointments) {
        queryClient.setQueryData(
          ["appointments"],
          context.previousAppointments
        );
      }
    },

    onSettled: async (_, __, params) => {
      await queryClient.invalidateQueries({ queryKey: ["appointments"] });
      await queryClient.invalidateQueries({
        queryKey: ["appointment", params.id],
      });
    },
  });
};
