import { useEffect, useState } from "react";
import {
  Modal,
  Input,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalContent,
  Checkbox,
  CheckboxGroup,
  Autocomplete,
  AutocompleteItem,
  ScrollShadow,
  Slider,
} from "@nextui-org/react";
import {
  PAYMENT_METHOD_LABELS,
  PAYMENT_METHODS,
  PaymentMethod,
  PaymentMethodIcon,
} from "../../../models/PaymentMethod";
import { HealthInsurance } from "../../../models/HealthInsurance";
import { formatCurrency } from "../../../utils/currencyUtils";

interface FiltersModalProps {
  onApplyFilter: (
    filterText: string,
    selectedPaymentMethods: PaymentMethod[],
    selectedHealthInsurance: string,
    priceRange: [number, number]
  ) => void;
  isOpen: boolean;
  onOpenChange: () => void;
  initialFilterText: string;
  initialSelectedPaymentMethods: PaymentMethod[];
  initialSelectedHealthInsurance: string;
  initialPriceRange: [number, number];
  healthInsurances: HealthInsurance[];
  isSpecialtySearch: boolean;
}

export const FiltersModal: React.FC<FiltersModalProps> = ({
  onApplyFilter,
  isOpen,
  onOpenChange,
  initialFilterText,
  initialSelectedPaymentMethods,
  initialSelectedHealthInsurance,
  initialPriceRange,
  healthInsurances,
  isSpecialtySearch,
}) => {
  const [filterText, setFilterText] = useState(initialFilterText);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    PaymentMethod[]
  >(initialSelectedPaymentMethods);
  const [selectedHealthInsurance, setSelectedHealthInsurance] = useState(
    initialSelectedHealthInsurance
  );
  const [priceRange, setPriceRange] =
    useState<[number, number]>(initialPriceRange);

  useEffect(() => {
    setFilterText(initialFilterText);
    setSelectedPaymentMethods(initialSelectedPaymentMethods);
    setSelectedHealthInsurance(initialSelectedHealthInsurance);
    setPriceRange(initialPriceRange);
  }, [
    initialFilterText,
    initialSelectedPaymentMethods,
    initialSelectedHealthInsurance,
    initialPriceRange,
  ]);

  const handleApplyFilter = () => {
    onApplyFilter(
      filterText,
      selectedPaymentMethods,
      selectedHealthInsurance,
      priceRange
    );
    onOpenChange();
  };

  const handlePaymentMethodChange = (value: string[]) => {
    setSelectedPaymentMethods(value as PaymentMethod[]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      placement="top"
      scrollBehavior="inside"
      className="max-h-[55vh]"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader>Filtrar Resultados</ModalHeader>
            <ScrollShadow>
              <ModalBody className="px-4 gap-4">
                <div className="flex flex-col gap-2">
                  <div className="w-full bg-default-100 rounded-medium p-2">
                    <h2 className="text-default-700 font-semibold text-sm">
                      Nome
                    </h2>
                  </div>
                  <Input
                    placeholder="Pesquisar por Nome"
                    value={filterText}
                    size="lg"
                    radius="md"
                    variant="bordered"
                    onValueChange={setFilterText}
                  />
                </div>

                <div className="flex flex-col gap-2">
                  <div className="w-full bg-default-100 rounded-medium p-2">
                    <h2 className="text-default-700 font-semibold text-sm">
                      Métodos de Pagamento
                    </h2>
                  </div>
                  <CheckboxGroup
                    value={selectedPaymentMethods}
                    onValueChange={handlePaymentMethodChange}
                    className="px-0.5"
                  >
                    {PAYMENT_METHODS.map((method) => (
                      <Checkbox key={method} value={method}>
                        <div className="flex items-center">
                          <PaymentMethodIcon method={method} />
                          <span className="ml-2">
                            {PAYMENT_METHOD_LABELS[method]}
                          </span>
                        </div>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </div>

                {!isSpecialtySearch && (
                  <>
                    <div className="flex flex-col gap-2">
                      <div className="w-full bg-default-100 rounded-medium p-2">
                        <h2 className="text-default-700 font-semibold text-sm">
                          Plano de Saúde
                        </h2>
                      </div>
                      <Autocomplete
                        size="lg"
                        radius="md"
                        variant="bordered"
                        label="Selecione o seu plano de saúde"
                        selectedKey={selectedHealthInsurance}
                        onSelectionChange={(value) =>
                          setSelectedHealthInsurance(value as string)
                        }
                        listboxProps={{
                          emptyContent: "Plano de saúde não encontrado.",
                        }}
                        classNames={{
                          clearButton: "w-12",
                        }}
                      >
                        {healthInsurances.map((insurance: HealthInsurance) => (
                          <AutocompleteItem
                            key={insurance.id}
                            value={insurance.id}
                            color="secondary"
                            variant="flat"
                          >
                            {insurance.id}
                          </AutocompleteItem>
                        ))}
                      </Autocomplete>
                    </div>

                    <div className="flex flex-col gap-2">
                      <div className="w-full bg-default-100 rounded-medium p-2">
                        <h2 className="text-default-700 font-semibold text-sm">
                          Faixa de Preço
                        </h2>
                      </div>
                      <Slider
                        step={5}
                        minValue={10}
                        maxValue={1000}
                        value={priceRange}
                        onChange={(value) =>
                          setPriceRange(value as [number, number])
                        }
                        className="px-0.5"
                      />
                      <div className="flex justify-between">
                        <span>
                          {formatCurrency(priceRange[0].toString(), 1)}
                        </span>
                        <span>
                          {formatCurrency(priceRange[1].toString(), 1)}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </ModalBody>
            </ScrollShadow>
            <ModalFooter>
              <Button color="danger" variant="flat" onPress={onClose}>
                Cancelar
              </Button>
              <Button onPress={handleApplyFilter} color="primary">
                Aplicar Filtro
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};
