import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ProviderInformation } from "../../models/ProviderInformation";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { DisplayTimer } from "../DisplayTimer";
import { SpecialtyAutoComplete } from "../SpecialtyAutoComplete";
import { DateUtils } from "../../utils/dateUtils";
import PaymentMethodAutocomplete from "../PaymentMethodAutocomplete";
import { ExamAutoComplete } from "../ExamAutoComplete";
import { HealthInsuranceAutoComplete } from "../HealthInsuranceAutoComplete";
import { useClinicsQuery } from "../../hooks/queries/useClinicQuery";
import { Clinic } from "../../models/Clinic";
import { ImageUpload } from "../ImageUpload/indes";
import { useScheduleManager } from "../../hooks/useScheduleManager";
import { produce } from "immer";
import { ExamDetailsInput } from "../ExamDetailsInput";
import { ServiceAutoComplete } from "../ServiceAutoComplete";
import { ServiceDetailsInput } from "../ServiceDetailsInput";
export interface ProviderFormModalProps {
  details: ProviderInformation;
  setDetails: React.Dispatch<React.SetStateAction<ProviderInformation>>;
}

export const ProviderFormModal: React.FC<ProviderFormModalProps> = ({
  details,
  setDetails,
}) => {
  const clinicsQuery = useClinicsQuery();
  const { handleTimeChange } = useScheduleManager(
    setDetails as React.Dispatch<
      React.SetStateAction<ProviderInformation | Clinic>
    >
  );

  const schedules = useMemo(
    () => ({
      appointments: {
        initTime: details.schedules?.appointments?.initTime
          ? DateUtils.fromDBFormat(
              details.schedules.appointments.initTime,
              details.timezone
            )
          : null,
        endTime: details.schedules?.appointments?.endTime
          ? DateUtils.fromDBFormat(
              details.schedules.appointments.endTime,
              details.timezone
            )
          : null,
        defaultDuration: details.schedules?.appointments?.defaultDuration
          ? DateUtils.fromDBFormat(
              details.schedules.appointments.defaultDuration,
              details.timezone
            )
          : null,
      },
      exams: {
        initTime: details.schedules?.exams?.initTime
          ? DateUtils.fromDBFormat(
              details.schedules.exams.initTime,
              details.timezone
            )
          : null,
        endTime: details.schedules?.exams?.endTime
          ? DateUtils.fromDBFormat(
              details.schedules.exams.endTime,
              details.timezone
            )
          : null,
        defaultDuration: details.schedules?.exams?.defaultDuration
          ? DateUtils.fromDBFormat(
              details.schedules.exams.defaultDuration,
              details.timezone
            )
          : null,
      },
    }),
    [details.schedules, details.timezone]
  );

  const [offersExams, setOffersExams] = useState<boolean>(
    !!details?.schedules?.exams || false
  );

  useEffect(() => {
    if (details.relatedClinicId && !details.acceptedHealthInsurances?.length) {
      const relatedClinic = clinicsQuery.data?.find(
        (clinic) => clinic.id === details.relatedClinicId
      );

      if (relatedClinic) {
        setDetails(
          produce((draft) => {
            draft.acceptedHealthInsurances =
              relatedClinic.acceptedHealthInsurances || [];
            draft.acceptedPaymentMethods =
              relatedClinic.acceptedPaymentMethods || [];
          })
        );
      }
    }
  }, [details.relatedClinicId, clinicsQuery.data, setDetails]);

  const handleTimezoneChange = useCallback(
    (newTimezone: string) => {
      setDetails(
        produce((draft) => {
          draft.timezone = newTimezone;
          if (draft.schedules.appointments) {
            draft.schedules.appointments.initTime = draft.schedules.appointments
              .initTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.appointments.initTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : undefined;
            draft.schedules.appointments.endTime = draft.schedules.appointments
              .endTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.appointments.endTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : undefined;
          }
          if (draft.schedules.exams) {
            draft.schedules.exams.initTime = draft.schedules.exams.initTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.exams.initTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : undefined;
            draft.schedules.exams.endTime = draft.schedules.exams.endTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.exams.endTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : undefined;
          }
        })
      );
    },
    [setDetails]
  );

  const handleExamChange = (examIds: string[]) => {
    setDetails(
      produce((draft) => {
        draft.schedules.exams = draft.schedules.exams || {};
        draft.schedules.exams.exams = examIds.reduce((acc, examId) => {
          acc[examId] = draft.schedules.exams?.exams?.[examId] || {
            name: examId,
            price: null,
            duration: null,
            acceptsHealthInsurances: null,
          };
          return acc;
        }, {} as Record<string, { name?: string | null; price?: string | null; duration?: number | null; acceptsHealthInsurances?: boolean | null }>);
      })
    );
  };

  const handleServiceChange = (serviceIds: string[]) => {
    setDetails(
      produce((draft) => {
        draft.schedules.appointments = draft.schedules.appointments || {};
        draft.schedules.appointments.services = serviceIds.reduce(
          (acc, serviceId) => {
            acc[serviceId] = draft.schedules.appointments?.services?.[
              serviceId
            ] || {
              name: serviceId,
              price: null,
              duration: null,
              acceptsHealthInsurances: null,
            };
            return acc;
          },
          {} as Record<
            string,
            {
              name?: string | null;
              price?: string | null;
              duration?: number | null;
              acceptsHealthInsurances?: boolean | null;
            }
          >
        );
      })
    );
  };

  return (
    <div className="space-y-6">
      <FormControlLabel
        control={<Checkbox />}
        label="Ativo"
        checked={!details.disabled}
        onChange={(_event, checked) =>
          setDetails({ ...details, disabled: !checked })
        }
      />

      <div className="border-t border-gray-200 pt-4">
        <Autocomplete
          options={clinicsQuery.data || []}
          getOptionLabel={(option) => option.name || ""}
          renderInput={(params) => (
            <TextField
              autoComplete="new-password"
              {...params}
              label="Clínica"
              variant="outlined"
            />
          )}
          value={
            clinicsQuery.data?.find(
              (clinic) => clinic.id === details.relatedClinicId
            ) || undefined
          }
          onChange={(_, value) =>
            setDetails({ ...details, relatedClinicId: value?.id })
          }
          disableClearable
        />
      </div>

      <div className="border-t border-gray-200 pt-4">
        <Typography variant="h6" className="mb-2">
          Imagem de perfil
        </Typography>
        <ImageUpload details={details} setDetails={setDetails} />
      </div>

      <div className="border-t border-gray-200 pt-4 space-y-4">
        <div className="flex gap-4">
          <TextField
            required
            label="Nome"
            variant="outlined"
            value={details.name || ""}
            onChange={({ target }) =>
              setDetails({ ...details, name: target.value })
            }
            className="flex-grow"
            autoComplete="off"
          />
          <TextField
            required
            label="Email"
            variant="outlined"
            value={details.email || ""}
            onChange={({ target }) =>
              setDetails({ ...details, email: target.value })
            }
            className="flex-grow"
            autoComplete="off"
          />
        </div>

        <TextField
          required
          label="Bio"
          placeholder="Descreva brevemente o(a) profissional"
          variant="outlined"
          value={details.readme || ""}
          onChange={({ target }) =>
            setDetails({ ...details, readme: target.value })
          }
          fullWidth
          multiline
          rows={3}
          autoComplete="off"
        />

        <TextField
          required
          label="Licença CRM"
          variant="outlined"
          value={details.crmCode || ""}
          onChange={({ target }) =>
            setDetails({ ...details, crmCode: target.value })
          }
          fullWidth
          autoComplete="off"
        />
      </div>

      <div className="border-t border-gray-200 pt-4 space-y-4">
        <SpecialtyAutoComplete
          value={details.specialties || []}
          setValue={(value) => setDetails({ ...details, specialties: value })}
        />

        <ServiceAutoComplete
          value={Object.keys(details.schedules.appointments?.services || {})}
          setValue={handleServiceChange}
        />
        <ServiceDetailsInput details={details} setDetails={setDetails} />

        <ExamAutoComplete
          value={Object.keys(details.schedules.exams?.exams || {})}
          setValue={handleExamChange}
        />
        <ExamDetailsInput
          details={details}
          setDetails={
            setDetails as React.Dispatch<
              React.SetStateAction<ProviderInformation | Clinic>
            >
          }
        />

        <HealthInsuranceAutoComplete
          value={details.acceptedHealthInsurances || []}
          setValue={(value) =>
            setDetails({
              ...details,
              acceptedHealthInsurances: value,
            })
          }
        />

        <PaymentMethodAutocomplete
          value={details.acceptedPaymentMethods || []}
          onChange={(value) =>
            setDetails({
              ...details,
              acceptedPaymentMethods: value,
            })
          }
        />
      </div>

      <div className="border-t border-gray-200 pt-4 space-y-4">
        <Autocomplete
          options={Intl.supportedValuesOf("timeZone")}
          renderInput={(params) => (
            <TextField {...params} label="Fuso horário" variant="outlined" />
          )}
          value={details.timezone}
          onChange={(_, newValue) => handleTimezoneChange(newValue || "")}
          disableClearable
        />

        {details.timezone && (
          <>
            <Typography variant="h6">Horários para Consultas</Typography>
            <div className="flex flex-wrap gap-4">
              <DesktopTimePicker
                ampm={false}
                label="Início de expediente"
                value={schedules.appointments.initTime}
                onChange={(value) =>
                  handleTimeChange("appointments", "initTime", value)
                }
                className="flex-grow"
              />
              <DesktopTimePicker
                ampm={false}
                label="Fim de expediente"
                value={schedules.appointments.endTime}
                minTime={schedules.appointments.initTime}
                onChange={(value) =>
                  handleTimeChange("appointments", "endTime", value)
                }
                className="flex-grow"
              />
              <DesktopTimePicker
                ampm={false}
                label="Duração fixa por consulta"
                value={schedules.appointments.defaultDuration}
                onChange={(value) =>
                  handleTimeChange("appointments", "defaultDuration", value)
                }
                className="flex-grow"
              />
            </div>

            <DisplayTimer
              entity={details}
              setEntity={
                setDetails as React.Dispatch<
                  React.SetStateAction<ProviderInformation | Clinic>
                >
              }
              scheduleType="appointments"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={offersExams}
                  onChange={({ target }) => setOffersExams(target.checked)}
                />
              }
              label="Oferecer exames"
            />

            {offersExams && (
              <>
                <Typography variant="h6">Horários para Exames</Typography>

                <div className="flex flex-wrap gap-4">
                  <DesktopTimePicker
                    ampm={false}
                    label="Início de expediente para exames"
                    value={schedules.exams.initTime}
                    onChange={(value) =>
                      handleTimeChange("exams", "initTime", value)
                    }
                    className="flex-grow"
                  />
                  <DesktopTimePicker
                    ampm={false}
                    label="Fim de expediente para exames"
                    value={schedules.exams.endTime}
                    minTime={schedules.exams.initTime}
                    onChange={(value) =>
                      handleTimeChange("exams", "endTime", value)
                    }
                    className="flex-grow"
                  />
                  <DesktopTimePicker
                    ampm={false}
                    label="Duração padrão para exames"
                    value={schedules.exams.defaultDuration}
                    onChange={(value) =>
                      handleTimeChange("exams", "defaultDuration", value)
                    }
                    className="flex-grow"
                  />
                </div>

                <DisplayTimer
                  entity={details}
                  setEntity={
                    setDetails as React.Dispatch<
                      React.SetStateAction<ProviderInformation | Clinic>
                    >
                  }
                  scheduleType="exams"
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
