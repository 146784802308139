import {
  Autocomplete,
  Button,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { CustomModal } from "../CustomModalInner";
import { Clinic, ClinicTypeEnum } from "../../models/Clinic";
import { useIBGECitiesQuery } from "../../hooks/queries/useIBGECities";
import { useIBGEStatesQuery } from "../../hooks/queries/useIBGEStates";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import PaymentMethodAutocomplete from "../PaymentMethodAutocomplete";
import { MaskedPhoneInput } from "../MaskedPhoneInput";
import { ClinicTimeMaskInput } from "../ClinicTimeMaskInput";
import { ExamAutoComplete } from "../ExamAutoComplete";
import { SpecialtyAutoComplete } from "../SpecialtyAutoComplete";
import { HealthInsuranceAutoComplete } from "../HealthInsuranceAutoComplete";
import { Close } from "@mui/icons-material";
import { useClinicsQuery } from "../../hooks/queries/useClinicQuery";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { DisplayTimer } from "../DisplayTimer";
import { DateUtils } from "../../utils/dateUtils";
import { useScheduleManager } from "../../hooks/useScheduleManager";
import { produce } from "immer";
import { ProviderInformation } from "../../models/ProviderInformation";
import { ExamDetailsInput } from "../ExamDetailsInput";

// TODO: \src\pages\AddClinic\index.tsx as reference
export const AddNewClinicLogic = () => {
  const clinicsQuery = useClinicsQuery();

  const [modal, setModal] = useState(false);
  const [clinicDetails, setClinicDetails] = useState<Clinic>({
    type: ClinicTypeEnum.Comum,
    timezone: "America/Sao_Paulo",
    schedules: {
      exams: {
        initTime: null,
        endTime: null,
        defaultDuration: null,
        disabledDays: [],
        disabledTimes: {},
      },
    },
  });

  const [offersExams, setOffersExams] = useState<boolean>(
    !!clinicDetails?.schedules?.exams || false
  );
  const statesQuery = useIBGEStatesQuery();
  const citiesQuery = useIBGECitiesQuery(
    statesQuery.data?.find(
      (state) =>
        state.nome?.toUpperCase() === clinicDetails.state?.nome?.toUpperCase()
    )
  );

  const { handleTimeChange } = useScheduleManager(
    setClinicDetails as React.Dispatch<
      React.SetStateAction<ProviderInformation | Clinic>
    >
  );

  const schedules = useMemo(
    () => ({
      exams: {
        initTime: DateUtils.fromDBFormat(
          clinicDetails.schedules?.exams?.initTime,
          clinicDetails.timezone
        ),
        endTime: DateUtils.fromDBFormat(
          clinicDetails.schedules?.exams?.endTime,
          clinicDetails.timezone
        ),
        defaultDuration: DateUtils.fromDBFormat(
          clinicDetails.schedules?.exams?.defaultDuration,
          clinicDetails.timezone
        ),
      },
    }),
    [clinicDetails.schedules, clinicDetails.timezone]
  );

  const handleTimezoneChange = useCallback(
    (newTimezone: string) => {
      setClinicDetails(
        produce((draft) => {
          draft.timezone = newTimezone;
          if (draft.schedules?.exams) {
            draft.schedules.exams.initTime = draft.schedules.exams.initTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.exams.initTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : null;
            draft.schedules.exams.endTime = draft.schedules.exams.endTime
              ? DateUtils.toDBFormat(
                  DateUtils.fromDBFormat(
                    draft.schedules.exams.endTime,
                    draft.timezone
                  ),
                  newTimezone
                )
              : null;
          }
        })
      );
    },
    [setClinicDetails]
  );

  const handleExamChange = (examIds: string[]) => {
    setClinicDetails(
      produce((draft) => {
        if (!draft.schedules) {
          draft.schedules = {};
        }
        if (!draft.schedules.exams) {
          draft.schedules.exams = {
            initTime: null,
            endTime: null,
            defaultDuration: null,
            disabledDays: [],
            disabledTimes: {},
            exams: {},
          };
        }
        draft.schedules.exams.exams = examIds.reduce((acc, examId) => {
          acc[examId] = draft.schedules?.exams?.exams?.[examId] || {
            name: examId,
            price: null,
            duration: null,
            acceptsHealthInsurances: null,
          };
          return acc;
        }, {} as Record<string, { name?: string | null; price?: string | null; duration?: number | null; acceptsHealthInsurances?: boolean | null }>);
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setModal(false);
      await addDoc(collection(fireStoreDb, `clinics`), clinicDetails);
      clinicsQuery.refetch();
      toast.success("Clínica salva com sucesso!");
      setClinicDetails({} as Clinic);
    } catch (error) {
      toast.error("Erro ao adicionar clínica");
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModal(true)}
      >
        Adicionar novo
      </Button>

      <CustomModal
        open={modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="p-5 flex gap-2 flex-col">
          <div className="flex items-center justify-between">
            <h2>Adicionar Nova Clínica</h2>
            <IconButton onClick={() => setModal(false)}>
              <Close />
            </IconButton>
          </div>
          <div className="flex w-full gap-2">
            <TextField
              autoComplete="new-password"
              required
              label="Nome"
              variant="outlined"
              value={clinicDetails.name}
              onChange={({ target }) =>
                setClinicDetails({ ...clinicDetails, name: target.value })
              }
              className="flex-grow"
            />
            <TextField
              autoComplete="new-password"
              required
              label="CNPJ"
              variant="outlined"
              value={clinicDetails.cnpj}
              onChange={({ target }) =>
                setClinicDetails({ ...clinicDetails, cnpj: target.value })
              }
              className="flex-grow"
            />
          </div>

          <div className="flex w-full gap-2">
            <Autocomplete
              options={statesQuery.data || []}
              getOptionLabel={(option) => option.nome || ""}
              renderInput={(params) => (
                <TextField
                  autoComplete="new-password"
                  {...params}
                  label="Estado"
                  variant="outlined"
                />
              )}
              value={clinicDetails.state || null}
              onChange={(_, value) =>
                setClinicDetails({
                  ...clinicDetails,
                  state: value || undefined,
                })
              }
              className="flex-grow"
            />
            <Autocomplete
              options={citiesQuery.data || []}
              getOptionLabel={(option) => option.nome || ""}
              renderInput={(params) => (
                <TextField
                  autoComplete="new-password"
                  {...params}
                  label="Cidade"
                  variant="outlined"
                />
              )}
              value={clinicDetails.city || null}
              onChange={(_, value) =>
                setClinicDetails({
                  ...clinicDetails,
                  city: value || undefined,
                })
              }
              disabled={!clinicDetails.state}
              className="flex-grow"
            />
          </div>

          <div className="flex w-full gap-2">
            <TextField
              autoComplete="new-password"
              required
              label="Email"
              variant="outlined"
              value={clinicDetails.email}
              onChange={({ target }) =>
                setClinicDetails({ ...clinicDetails, email: target.value })
              }
              className="flex-grow"
            />

            <TextField
              autoComplete="new-password"
              required
              label="Telefone da clínica"
              variant="outlined"
              InputProps={{ inputComponent: MaskedPhoneInput as any }}
              value={clinicDetails.phoneNumber}
              onChange={({ target }) =>
                setClinicDetails({
                  ...clinicDetails,
                  phoneNumber: target.value,
                })
              }
              className="flex-grow"
            />
          </div>
          <TextField
            autoComplete="new-password"
            required
            label="Endereço"
            variant="outlined"
            value={clinicDetails.address}
            onChange={({ target }) =>
              setClinicDetails({ ...clinicDetails, address: target.value })
            }
          />

          <SpecialtyAutoComplete
            value={clinicDetails.offeredSpecialties || []}
            setValue={(value) =>
              setClinicDetails({ ...clinicDetails, offeredSpecialties: value })
            }
          />
          <ExamAutoComplete
            value={Object.keys(clinicDetails.schedules?.exams?.exams || {})}
            setValue={handleExamChange}
          />
          <ExamDetailsInput
            details={clinicDetails}
            setDetails={
              setClinicDetails as React.Dispatch<
                React.SetStateAction<ProviderInformation | Clinic>
              >
            }
          />

          <HealthInsuranceAutoComplete
            value={clinicDetails.acceptedHealthInsurances || []}
            setValue={(value) =>
              setClinicDetails({
                ...clinicDetails,
                acceptedHealthInsurances: value,
              })
            }
          />

          <PaymentMethodAutocomplete
            value={clinicDetails.acceptedPaymentMethods || []}
            onChange={(value) =>
              setClinicDetails({
                ...clinicDetails,
                acceptedPaymentMethods: value,
              })
            }
          />
          <ClinicTimeMaskInput
            label="Horário de funcionamento"
            required
            value={clinicDetails.openingHours || ""}
            onChange={(value) =>
              setClinicDetails({ ...clinicDetails, openingHours: value })
            }
          />

          <Autocomplete
            options={Intl.supportedValuesOf("timeZone")}
            renderInput={(params) => (
              <TextField {...params} label="Fuso horário" variant="outlined" />
            )}
            value={clinicDetails.timezone}
            onChange={(_, newValue) => handleTimezoneChange(newValue)}
            disableClearable
          />

          {clinicDetails.timezone && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offersExams}
                    onChange={({ target }) => setOffersExams(target.checked)}
                  />
                }
                label="Oferecer exames para plantonistas"
              />

              {offersExams && (
                <>
                  <h2>Agenda para Exames</h2>

                  <div className="flex w-full gap-2 flex-wrap">
                    <DesktopTimePicker
                      ampm={false}
                      label="Início de expediente para exames"
                      value={schedules.exams.initTime}
                      onChange={(value) =>
                        handleTimeChange("exams", "initTime", value)
                      }
                      className="flex-grow"
                    />
                    <DesktopTimePicker
                      ampm={false}
                      label="Fim de expediente para exames"
                      value={schedules.exams.endTime}
                      minTime={schedules.exams.initTime}
                      onChange={(value) =>
                        handleTimeChange("exams", "endTime", value)
                      }
                      className="flex-grow"
                    />
                    <DesktopTimePicker
                      ampm={false}
                      label="Duração padrão para exames"
                      value={schedules.exams.defaultDuration}
                      onChange={(value) =>
                        handleTimeChange("exams", "defaultDuration", value)
                      }
                      className="flex-grow"
                    />
                  </div>

                  {clinicDetails.schedules?.exams && (
                    <DisplayTimer
                      entity={clinicDetails}
                      setEntity={
                        setClinicDetails as React.Dispatch<
                          React.SetStateAction<ProviderInformation | Clinic>
                        >
                      }
                      scheduleType="exams"
                    />
                  )}
                </>
              )}
            </>
          )}

          <h2>Informações do Responsável</h2>

          <div className="flex w-full gap-2">
            <TextField
              autoComplete="new-password"
              required
              label="Nome"
              variant="outlined"
              value={clinicDetails?.manager?.name}
              onChange={({ target }) =>
                setClinicDetails({
                  ...clinicDetails,
                  manager: { ...clinicDetails.manager, name: target.value },
                })
              }
              className="flex-grow"
            />
            <TextField
              autoComplete="new-password"
              required
              label="CPF"
              variant="outlined"
              value={clinicDetails?.manager?.cpf}
              onChange={({ target }) =>
                setClinicDetails({
                  ...clinicDetails,
                  manager: { ...clinicDetails.manager, cpf: target.value },
                })
              }
              className="flex-grow"
            />
          </div>
          <div className="flex w-full gap-2">
            <TextField
              autoComplete="new-password"
              required
              label="E-mail"
              variant="outlined"
              value={clinicDetails?.manager?.email}
              onChange={({ target }) =>
                setClinicDetails({
                  ...clinicDetails,
                  manager: { ...clinicDetails.manager, email: target.value },
                })
              }
              className="flex-grow"
            />

            <TextField
              autoComplete="new-password"
              required
              label="Número de Telefone"
              variant="outlined"
              value={clinicDetails?.manager?.phoneNumber}
              InputProps={{ inputComponent: MaskedPhoneInput as any }}
              onChange={({ target }) =>
                setClinicDetails({
                  ...clinicDetails,
                  manager: {
                    ...clinicDetails.manager,
                    phoneNumber: target.value,
                  },
                })
              }
              className="flex-grow"
            />
          </div>

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Adicionar
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};
