import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button, IconButton } from "@mui/material";
import { CustomModal } from "../CustomModalInner";
import { ProviderInformation } from "../../models/ProviderInformation";
import { ProviderFormModal } from "../FormModalLogic";
import { setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { fireStoreDb } from "../../services/firebase/firebase";
import { useProvidersQuery } from "../../hooks/queries/useProviderQuery";
import { Close } from "@mui/icons-material";

export interface EditProviderLogicProps {
  selectedProvider: ProviderInformation | null;
  healthInsurances: string[];
  onClose: () => void;
}

export const EditProviderLogic: React.FC<EditProviderLogicProps> = React.memo(
  ({ selectedProvider, healthInsurances, onClose }) => {
    const providersQuery = useProvidersQuery();
    const [details, setDetails] = useState<ProviderInformation>({
      id: "",
      disabled: false,
      relatedClinicId: null,
      relatedClinic: null,
      imageUrl: null,
      name: null,
      specialties: [],
      exams: [],
      timezone: "America/Sao_Paulo",
      acceptedHealthInsurances: [],
      acceptedPaymentMethods: [],
      readme: null,
      email: null,
      crmCode: "",
      schedules: {
        appointments: {
          initTime: "",
          endTime: "",
          defaultDuration: "",
          disabledDays: [],
          disabledTimes: {},
        },
        exams: {},
      },
      averageRating: 0,
      totalReviews: 0,
      appointments: [],
    });

    useEffect(() => {
      if (selectedProvider) {
        setDetails({
          ...selectedProvider,
          schedules: {
            appointments: selectedProvider.schedules?.appointments || {
              initTime: "",
              endTime: "",
              defaultDuration: "",
              disabledDays: [],
              disabledTimes: {},
            },
            exams: selectedProvider.schedules?.exams || {},
          },
        });
      } else {
        setDetails({
          id: "",
          disabled: false,
          relatedClinicId: null,
          relatedClinic: null,
          imageUrl: null,
          name: null,
          specialties: [],
          exams: [],
          timezone: "America/Sao_Paulo",
          acceptedHealthInsurances: [],
          acceptedPaymentMethods: [],
          readme: null,
          email: null,
          crmCode: "",
          schedules: {
            appointments: {
              initTime: "",
              endTime: "",
              defaultDuration: "",
              disabledDays: [],
              disabledTimes: {},
            },
            exams: {},
          },
          averageRating: 0,
          totalReviews: 0,
          appointments: [],
        });
      }
    }, [selectedProvider]);

    const handleSubmit = useCallback(async () => {
      if (!details.id) {
        toast.error("ID do profissional é obrigatório");
        return;
      }

      try {
        await setDoc(doc(fireStoreDb, "providers", details.id), details, {
          merge: true,
        });
        await providersQuery.refetch();
        toast.success("Profissional salvo com sucesso!");
        onClose();
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          toast.error(`Erro ao editar profissional: ${error.message}`);
        } else {
          toast.error("Erro desconhecido ao editar profissional");
        }
      }
    }, [details, providersQuery, onClose]);

    const modalContent = useMemo(
      () => (
        <div className="p-5 flex gap-3 flex-col">
          <div className="flex items-center justify-between">
            <h2>Editar profissional</h2>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </div>

          <ProviderFormModal details={details} setDetails={setDetails} />

          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Salvar
          </Button>
        </div>
      ),
      [details, setDetails, healthInsurances, handleSubmit, onClose]
    );

    return (
      <CustomModal
        open={selectedProvider !== null}
        modalInnerProps={{
          style: {
            maxWidth: 900,
          },
        }}
      >
        {modalContent}
      </CustomModal>
    );
  }
);
