import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  TextField,
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox, Add } from "@mui/icons-material";
import { CustomModal } from "../CustomModalInner";
import React, { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import { fireStoreDb } from "../../services/firebase/firebase";
import { toast } from "react-toastify";
import { useHealthInsurancesQuery } from "../../hooks/queries/useHealthInsurancesQuery";

export interface HealthInsuranceAutoCompleteProps {
  value: string[];
  setValue: (value: string[]) => void;
  placeholder?: string;
}

export const HealthInsuranceAutoComplete: React.FC<
  HealthInsuranceAutoCompleteProps
> = ({ value, setValue, placeholder }) => {
  const [addHealthInsuranceModalActive, setAddHealthInsuranceModalActive] =
    useState(false);
  const [newHealthInsurance, setNewHealthInsurance] = useState<string>("");

  const {
    data: healthInsurancesQuery,
    isLoading,
    refetch,
  } = useHealthInsurancesQuery();

  const handleAddHealthInsurance = async () => {
    if (!newHealthInsurance) return;
    try {
      const docRef = doc(
        collection(fireStoreDb, "healthInsurances"),
        newHealthInsurance
      );
      await setDoc(docRef, { id: newHealthInsurance });
      setValue([...value, docRef.id]);
      setNewHealthInsurance("");
      setAddHealthInsuranceModalActive(false);
      refetch();
      toast.success("Novo plano de saúde adicionada com sucesso!");
    } catch (error) {
      console.error("Error adding new healhInsurance: ", error);
      toast.error("Erro ao adicionar novo plano de saúde");
    }
  };

  return (
    <div className="flex items-center gap-2">
      <Autocomplete
        multiple
        options={healthInsurancesQuery || []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.id}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlank fontSize="small" />}
              checkedIcon={<CheckBox fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.id}
          </li>
        )}
        value={
          healthInsurancesQuery?.filter((healthInsurance) =>
            value.includes(healthInsurance.id)
          ) || []
        }
        onChange={(_event, newValue) => {
          setValue(newValue.map((v) => v.id));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder ? placeholder : "Planos de Saúde"}
          />
        )}
        fullWidth
        loading={isLoading}
      />
      <div>
        <IconButton
          color="primary"
          onClick={() => setAddHealthInsuranceModalActive(true)}
          children={<Add />}
        />
      </div>
      <CustomModal
        open={addHealthInsuranceModalActive}
        onClose={() => setAddHealthInsuranceModalActive(false)}
      >
        <div className="p-5 d-flex flex-column gap-3">
          <TextField
            autoComplete="new-password"
            required
            label="Novo Plano de Saúde"
            variant="outlined"
            value={newHealthInsurance}
            onChange={({ target }) => setNewHealthInsurance(target.value)}
          />
          <Button variant="contained" onClick={handleAddHealthInsurance}>
            Adicionar
          </Button>
        </div>
      </CustomModal>
    </div>
  );
};
